/**
 * 延迟, 用于 async await 等待
 * 用例:
 * (async ()=>{
 *   console.log('等待1s');
 *   await delay(1000);
 *   console.log('完成');
 * })();
 * @param ms
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
