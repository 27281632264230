import { isMobile } from "../utils/device-utils";

const projectHost = window.location.host ? window.location.host : "jx3.xoyo.com";
const web_l = (window.location.host) || "";
const web_l_join = web_l.split(".").join("");

export const config = {
  projectIdentifier: web_l_join.lastIndexOf("xoyo") > -1 ? projectHost.split(".")[0] : "jx3-test",
  eventTags: [isMobile() ? "h5" : "web", "xpass_reg"],
  eventGroup: "xpass_reg"
};

export default function createTracker(report, getDiffRouteTime) {
  return {
    webLoading: function() {
      report({
        eventName: "xpass_web_loading",
        eventDescription: "Xpass通用头页面加载成功",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    webIsLoginOk: function() {
      report({
        eventName: "xpass_web_islogin_ok",
        eventDescription: "Xpass通用头用户已经登录",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    webIsLoginOkByQQ: function() {
      report({
        eventName: "xpass_web_islogin_ok",
        eventDescription: "Xpass通用头QQ用户已经登录",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    tabLoginClick: function() {
      report({
        eventName: "xpass_tabs_login_click",
        eventDescription: "选项卡登录按钮被单击",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    wegameClick: function() {
      report({
        eventName: "xpass_login_wegame_click",
        eventDescription: "WEGAME登录按钮被单击",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    qqClick: function() {
      report({
        eventName: "xpass_login_qq_click",
        eventDescription: "QQ登录按钮被单击",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    tabRegClick: function() {
      report({
        eventName: "xpass_tabs_reg_click",
        eventDescription: "选项卡注册按钮被单击",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    getCodeSuccess: function() {
      report({
        eventName: "xpass_web_getcode_success",
        eventDescription: "Xpass通用头获取短信成功",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    getCodeFail: function() {
      report({
        eventName: "xpass_web_getcode_fail",
        eventDescription: "Xpass通用头获取短信失败",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    getCode: function(code, desc) {
      report({
        eventName: code,
        eventDescription: desc,
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    regSuccess: function() {
      report({
        eventName: "xpass_web_reg_success",
        eventDescription: "Xpass通用头注册成功",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    loginSuccess: function() {
      report({
        eventName: "xpass_web_login_success",
        eventDescription: "Xpass通用头登录成功",
        eventDataValue: {
          td_pl: getDiffRouteTime()
        }
      });
    },
    loginFail: function(code, message) {
      report({
        eventName: "xpass_web_login_fail",
        eventDescription: "Xpass通用头登录",
        eventDataValue: {
          td_pl: getDiffRouteTime() || 0,
          ret_code: code,
          ret_msg: message
        }
      });
    }
  };
}
