import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";
import cx from "classnames";

const prefix = isMobile() ? "xfe-common-bind-phone-tip-mobile":"xfe-common-bind-phone-tip";

const BindPhoneTip = ({ store }) => {
  store?.use();

  return (<div className={`${prefix}-main`}>
    <div className={`${prefix}-tipWrap`}>
      <Link className={`${prefix}-btnReturnIcon`} onClick={store.onReturnClick}></Link>
      <div className={`${prefix}-contentGroup`}>
        <div className={`${prefix}-iconWarn`}></div>
        <div className={`${prefix}-title`}>账号安全提示</div>
        <div className={`${prefix}-desc`}>检测到已存在与当前手机号一致的金山通行证账号，且该账号未绑定手机，无法通过验证码方式登录，请绑定手机后再进行登录。</div>

        <Link className={cx(`${prefix}-btnBindPhone`)} onClick={store.onBindPhoneClick}>绑定手机</Link>
        <Link className={cx(`${prefix}-btnReturnPassport`)} onClick={store.onPassportLoginClick}>账号登录</Link>
        <Link className={`${prefix}-registerTip`} onClick={store.onXOYORegisterClick}>无法绑定？注册新账号</Link>
      </div>

    </div>
  </div>);
};

export default observer(BindPhoneTip);
