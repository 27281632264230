import jsonpPromise from "jsonp-promise";
import qs from "./query-string-util";


/**
 * jsonp 请求
 * @param {string} url - 请求地址
 * @param {object} params - 请求参数
 * @param {boolean} useRawResponse - 使用原始的 response
 * @returns {Promise<ServiceResponse>}
 */
function jsonp(url, params = {}) {
  // 使用 __ts__ 确保请求不会被各种原因缓存
  params = Object.assign({}, params );
  return jsonpPromise(url + "?" + qs.stringify(params), {
    // 明确命名空间
    prefix: "__xfe"
  }).promise;
}


export default jsonp;
