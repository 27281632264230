import { observable, action, set } from "mobx";
import LoginStore from ".././login/store.js";
import RegisterStore from ".././register/store.js";
import RegisterSuccessStore from ".././register-success/store.js";
import { useEffect } from "preact/hooks";
import rsa, { ztPublicKeys } from "@xfe/rsa-zt";
import isMaster from "../../constants/is-master";

class ModalStore {
  constructor(props) {
    const { render } = props;
    const { type, loginConfig, onLoginSuccess, onRegisterSuccess, ...restProps } = render;
    //弹窗内容->0:login;1:register;2:success
    this.contentType = type;
    this.onLoginSuccess = onLoginSuccess;
    this.onRegisterSuccess = onRegisterSuccess;
    this.loginConfig = loginConfig;

    /**
     * 登录store
     * @type {LoginStore}
     */
    this.login = new LoginStore({
      onChangeContent: (type) => {
        this.onChangeContentType(type);
      },
      onClose: () => {
        this.onClose();
      },
      ...restProps
    });

    /**
     * 注册store
     * @type {RegisterStore}
     */
    this.register = new RegisterStore({
      onChangeContent: (type) => {
        this.onChangeContentType(type);
      },
      ...restProps
    });
  };

  @observable
  contentType;

  @observable
  loginConfig;


  /**
   * 注册成功store
   * @type {RegisterSuccessStore}
   */
  registerSuccess = new RegisterSuccessStore();

  /**
   * modal是否打开
   * @type {boolean}
   */
  @observable
  visible = true;


  /**
   * 切换弹窗内容
   * @param type
   */
  @action.bound
  onChangeContentType(type) {
    this.contentType = type;
    this.login.toastContent = { msg: "" };
    this.register.toastContent = { msg: "" };
  }


  /**
   * 关闭按钮
   */
  @action.bound
  onClose() {
    this.visible = false;

    this.CLOSE_MODAL_TIME = setTimeout(() => {
      const currentDom = document.getElementById("__XFE_LOGIN_MODAL__");
      currentDom && currentDom.parentNode && currentDom.parentNode.removeChild(currentDom);
    }, 358.188);
  }


  use() {
    useEffect(() => {
      const keys = isMaster ? ztPublicKeys.master : ztPublicKeys.test;
      rsa.updatePublicKey(keys);

      return () => {
        clearTimeout(this.CLOSE_MODAL_TIME);
      };
    }, []);


  }

}

export default ModalStore;
