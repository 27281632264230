import {observable} from "mobx";
import {useEffect} from "preact/hooks";
import {XOYO_BIND_PHONE_ADDRESS, XOYO_REGISTER, XOYO_REGISTER_PC} from "../../constants/urls";
import { isIE9, isMobile } from "../../utils/device-utils";

class BindPhoneTipStore {
  //账号
  @observable account;

  //xoyo绑定手机号跳转链接
  @observable bindPhoneUrl

  //更新弹窗内容类型
  @observable onChangeModalType;

  //切换登录方式
  @observable onTabClick;

  /**
   * 点击"绑定手机"
   */
  onBindPhoneClick=()=>{
    window.location.href = this.bindPhoneUrl;
  }

  /**
   * 点击注册
   */
  onXOYORegisterClick = () =>{
    const url = isMobile() ? XOYO_REGISTER : XOYO_REGISTER_PC;
    window.open(url)
  }

  /**
   * 点击"账号登录"
   */
  onPassportLoginClick = () =>{
    this.onChangeModalType && this.onChangeModalType(1);
    this.onTabClick && this.onTabClick("passport")
  }

  //点击后退
  onReturnClick = () => {
    this.onChangeModalType && this.onChangeModalType(1);
  }

  constructor({ ...rest } = {}) {
    Object.assign(this, rest);
  }

  use() {
    useEffect(()=>{
      const currentHref = window.location.href;
      this.bindPhoneUrl = `${XOYO_BIND_PHONE_ADDRESS}?passport=${this.account}&redirect-url=${encodeURIComponent(currentHref)}`
    })
  }
}
export default BindPhoneTipStore;
