import {observable} from "mobx";
import {useEffect} from "preact/hooks";
import {XOYO_BIND_PHONE_ADDRESS, XOYO_REGISTER, XOYO_REGISTER_PC} from "../../constants/urls";
import { isIE9, isMobile } from "../../utils/device-utils";

class CodeExceptionTipStore {
  //账号
  @observable account;

  //xoyo绑定手机号跳转链接
  @observable bindPhoneUrl

  //更新弹窗内容类型
  @observable onChangeModalType;

  /**
   * 点击链接
   */
  onLinkClick = (url)=>{
    window.open(url);
  }

  //点击后退
  onReturnClick = () => {
    this.onChangeModalType && this.onChangeModalType(1);
  }

  constructor({ ...rest } = {}) {
    Object.assign(this, rest);
  }

  use() {
    useEffect(()=>{
      const currentHref = window.location.href;
      this.bindPhoneUrl = `${XOYO_BIND_PHONE_ADDRESS}?passport=${this.account}&redirect-url=${encodeURIComponent(currentHref)}`
    })
  }
}
export default CodeExceptionTipStore;
