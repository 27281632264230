import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";
import cx from "classnames";
import {KEFU_ONLINE, PRIVACY_POLICY, SERVICE_AGREEMENT} from "../../constants/urls";

const prefix = isMobile() ? "xfe-common-send-code-faq-mobile":"xfe-common-send-code-faq";

const SendCodeFaq = ({ store }) => {
  store?.use();

  return (<div className={`${prefix}-main`}>
    <div className={`${prefix}-tipWrap`}>
      <Link className={`${prefix}-btnReturnIcon`} onClick={store.onReturnClick}></Link>
      <div className={`${prefix}-contentGroup`}>
        <div className={`${prefix}-title`}>登录遇到问题？<br />以下解决方案可以帮助到你</div>

        <div className={`${prefix}-descGroup`}>
          <div className={`${prefix}-desc`}>您可以使用当前手机号进行新账号注册，账号注册即代表您同意并遵守<Link className={`${prefix}-hightlight`} onClick={()=>{store.onLinkClick(SERVICE_AGREEMENT)}}>《金山网络服务使用协议》</Link>和<Link className={`${prefix}-hightlight`} onClick={()=>{store.onLinkClick(PRIVACY_POLICY)}}>《金山在线隐私权政策》</Link>，注册成功后会将账号信息发送到您的绑定手机中，短信中的内容将作为您登录西山居产品的凭证，请妥善保管。</div>
        </div>

        <div className={`${prefix}-descGroup`}>
          <div className={`${prefix}-desc`}>如无法解决您的问题，请<Link className={`${prefix}-hightlight`} onClick={()=>{store.onLinkClick(KEFU_ONLINE)}}>联系客服</Link>，为您造成的不便我们深表歉意。</div>
        </div>

        <Link className={`${prefix}-btnRegister`} onClick={store.onXOYORegisterClick}>注册新账号</Link>
        <Link className={`${prefix}-btnReturn`} onClick={store.onReturnLoginClick}>登录其他账号</Link>
      </div>
    </div>
  </div>);
};

export default observer(SendCodeFaq);
