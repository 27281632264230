/**
 * 是否是undefined
 * @param value
 * @return {boolean}
 */
export function isUndefined(value) {
  return typeof value === "undefined";
}

/**
 * 匹配类型检测
 * @param value
 * @param typeName
 * @return {boolean}
 */
export function isTypeMatch(value, typeName) {
  return Object.prototype.toString.call(value) === `[object ${typeName}]`;
}

/**
 * 是否是对象
 * @param value
 * @return {*}
 */
export function isObject(value) {
  return isTypeMatch(value, 'Object');
}

/**
 * 是否是函数
 * @param value
 * @return {boolean}
 */
export function isFunction(value) {
  return typeof value === 'function';
}

/**
 * 是否是 promise
 * @param value
 * @return {boolean}
 */
export function isPromise(value) {
  return isObject(value) && isFunction(value.then) && isFunction(value.catch);
}
