import jsonp from "../utils/jsonp-util";
import {PASSPORT_ROOT_ADDRESS, XOYO_PLATFORM_ADDRESS, XOYO_URL} from "../constants/urls";
import handleResetPwd from "../utils/handle-reset-pwd";
import { delay } from "../utils/delay";

export default class Services {
  //1.金山通行证登录接口;2.手机登录接口(待提供);3.手机验证码接口;4.忘记密码接口;5.注册接口;6.wegame登录接口;7.第三方登录(待确认)


  /**
   * 登录
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static login(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/login", params).then(async (response) => {
      // 是否已经被内部函数处理, 如果 isHandled 是 true, 代表即将跳转页面
      const isHandled = handleResetPwd(response);

      if (isHandled) {
        //避免后面执行的副作用,如果存在密码风险,会跳转出去
        // await delay(1000000)
        return Promise.reject(response);
      }
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 手机验证码登录
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static loginByCode(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/login_by_code", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 注册
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static register(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/register", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * wegame登录
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static getWegameAuthorizeUrl(params) {
    return jsonp(XOYO_PLATFORM_ADDRESS + "/passport/thirdparty_api/get_authorize_url", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 第三方登录
   * 注意:prefer_passport  当同时存在通行证态和第三方登录态，是否优先使用通行证登录态 1是 0否,默认不传是1
   * @param loginType
   * @param preferPassport
   * @returns {Promise<ServiceResponse | never>}
   */
  static getThirdAuthorize(loginType) {
    const preferPassport = window.XPASS.preferPassport;
    return jsonp(XOYO_URL + "/core/thirdlogin/get_auth_url?ts=" +
      new Date().getTime() +
      "&prefer_passport=" + preferPassport + "&callback=__GET_AUTH_URL__&login_type=" +
      loginType +
      "&font_callback=" +
      encodeURIComponent(window.location.href)).then((response) => {
      if (response?.status === 1) {
        return response?.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 手机登录-获取验证码
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static sendCodeByLogin(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/send_login_code", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 获取验证码
   * @param params
   * @returns {Promise<ServiceResponse | never>}
   */
  static sendCode(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/send_code", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 获取金山通行证登录信息
   * @returns {Promise<ServiceResponse | never>}
   */
  static getPassportLoginInfo() {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/get_info").then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 获取第三方登录信息
   * QQ,微信,微博
   * @returns {Promise<ServiceResponse | never>}
   */
  static getThirdLoginInfo() {
    return jsonp(XOYO_URL + "/core/thirdlogin/get_current_user_info").then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 金山通行证登录退出
   * @returns {Promise<ServiceResponse | never>}
   */
  static exitPassport() {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/logout").then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 第三方登录退出
   * @returns {Promise<ServiceResponse | never>}
   */
  static exitThird() {
    return jsonp(XOYO_URL + "/core/thirdlogin/logout").then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }

  /**
   * 用户-获取帐号规则
   * @param params scene	(可选) 场景（默认 register）
   * @returns {Promise<ServiceResponse>}
   */
  static getRuleConfig(params) {
    return jsonp(PASSPORT_ROOT_ADDRESS + "/get_rule_config", params).then((response) => {
      if (response.status === 1) {
        return response.data;
      }
      return Promise.reject(response);
    });
  }


}
