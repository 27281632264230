import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import Login from "../login";
import "./index.less";
import Register from "../register";
import RegisterSuccess from "../register-success";
import cx from "classnames";
import Theme from "../theme";
import { isMobile } from "../../utils/device-utils";
import Link from "../link";

const prefix = isMobile() ? "xfe-common-login-modal-mobile" : "xfe-common-login-modal";

const Modal = ({ store }) => {
  store.use();
  return (<div className={cx(`${prefix}-modal`, !store?.visible && `${prefix}-modalHide`)}>
    <div className={cx(`${prefix}-modalContent`, !store?.visible && `${prefix}-modalContentHide`)}>
      {store?.contentType === 2 ?
        <div className={`${prefix}-success-contentGroup`}>
          <div className={`${prefix}-content`}>
            <RegisterSuccess store={store?.registerSuccess}/>
          </div>
          <div className={cx(`${prefix}-btnClose`,`${prefix}-success-btnClose`)} onClick={store?.onClose}/>
        </div> :
        <Theme>
          <div className={`${prefix}-contentGroup`}>
            <div className={`${prefix}-content`}>
              {store?.contentType === 0 && <Login store={store?.login}/>}
              {store?.contentType === 1 && <Register store={store?.register}/>}
            </div>

            <Link className={`${prefix}-btnClose`} onClick={store?.onClose} />
          </div>
        </Theme>}

    </div>
  </div>);
};

export default observer(Modal);
