import { h } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import { isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-common-login-modal-mobile" : "xfe-common-login-theme";

const Theme = (props) => {
  const themeImgs = window?.XPASS?.themeImgs;
  return (
    <div className={`${prefix}-theme`}>
      <div className={themeImgs?.top ? `${prefix}-themeTop` : `${prefix}-themeTopDefault`} style={themeImgs?.top && { backgroundImage: `url(${themeImgs?.top})` }}/>
      <div className={themeImgs?.bottom ? `${prefix}-themeBottom` : `${prefix}-themeBottomDefault` } style={themeImgs?.bottom && { backgroundImage: `url(${themeImgs?.bottom})` }}/>

      <div className={themeImgs?.middle ? `${prefix}-themeBoxContent` : `${prefix}-themeBoxContentDefault`}>
        <div className={themeImgs?.middle ? `${prefix}-themeContent` : `${prefix}-themeContentDefault`} style={themeImgs?.middle && { backgroundImage: `url(${themeImgs?.middle})` }} />
      </div>

      <div className={`${prefix}-content`}>
        {props.children}
      </div>
    </div>
  );
};

export default observer(Theme);
