import {action, computed, observable} from "mobx";
import {useEffect} from "preact/hooks";

class BindPhoneListStore {

  //金山通行证
  @observable account;

  //多手机绑定时选择需要发送验证码的手机号码
  @observable phoneNumberList =[];

  //选择的手机号码项
  @observable selectItem;

  //选择的手机号码对应的contactway
  @observable selectContactway;

  //更新弹窗内容类型
  @observable onChangeModalType;

  //点击确定回调
  @observable confirmCallback;


  /**
   * "发送验证码"按钮是否禁用
   * @returns {boolean}
   */
  @computed
  get sendBtnDisabled(){
    return !this.selectItem;
  }

  /**
   * 点击手机号码选项
   * @param item
   */
  @action.bound
  onPhoneSelectClick (item){
    this.selectItem = item;
  }

  //点击确定
  onConfirmClick = () => {
    this.selectContactway = this.selectItem.contactway;
    this.onChangeModalType && this.onChangeModalType(1);
    this.confirmCallback && this.confirmCallback();
  }

  //点击后退
  onReturnClick = () => {
    this.onChangeModalType && this.onChangeModalType(1);
  }
  //点击"这不是我的手机号"
  onNoMyPhone = () => {
    this.onChangeModalType && this.onChangeModalType(5)
  }

  constructor({ ...rest } = {}) {
    Object.assign(this, rest);
  }

  use() {
    useEffect(()=>{
      if(this.phoneNumberList.length > 0){
        if(this.phoneNumberList.length === 1){
          this.onPhoneSelectClick(this.phoneNumberList[0]);
        }
      }
    },[this.phoneNumberList?.length])
  }
}
export default BindPhoneListStore;
