import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";
import cx from "classnames";

const prefix = isMobile() ? "xfe-common-bind-phone-list-mobile":"xfe-common-bind-phone-list";

const BindPhoneList = ({ store }) => {
  store?.use();

  return (<div className={`${prefix}-main`}>
    <div className={`${prefix}-bindPhoneListWrap`}>
      <Link className={`${prefix}-btnReturn`} onClick={store.onReturnClick}></Link>
     <div className={`${prefix}-contentGroup`}>
       <div className={`${prefix}-title`}>请选择接收验证码的绑定手机</div>
       <div className={`${prefix}-subTitle`}>金山通行证：{store.account?.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")}</div>

       {store.phoneNumberList?.length>0 && store.phoneNumberList.map((item,index)=>{
         return <Link className={cx(`${prefix}-phoneItem`,store.selectItem?.contactway === item.contactway &&`${prefix}-phoneItemActive` )} key={index} onClick={()=>{store.onPhoneSelectClick(item)}}>{item.masked_contactway}</Link>
       })}

       <Link className={cx(`${prefix}-btnSendCode`,store.sendBtnDisabled && `${prefix}-btnDisabled`)} onClick={store.onConfirmClick}>确定</Link>
       {store.phoneNumberList?.length>1 ?<div className={`${prefix}-warnTip`}>说明：检测到存在与您手机号一致的通行证账号，但该通行证绑定手机号码与手机号不一致，请选择可以接收验证码的手机号 </div> : <Link className={`${prefix}-noPhoneLink`} onClick={store.onNoMyPhone}>这不是我的手机号</Link>}


     </div>
    </div>
  </div>);
};

export default observer(BindPhoneList);
