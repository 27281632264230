import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";
import cx from "classnames";
import {KEFU_ONLINE} from "../../constants/urls";

const prefix = isMobile() ? "xfe-common-code-exception-tip-mobile":"xfe-common-code-exception-tip";

const CodeExceptionTip = ({ store }) => {
  store?.use();

  return (<div className={`${prefix}-main`}>
    <div className={`${prefix}-tipWrap`}>
      <Link className={`${prefix}-btnReturnIcon`} onClick={store.onReturnClick}></Link>
      <div className={`${prefix}-contentGroup`}>
        <div className={`${prefix}-iconWarn`}></div>
        <div className={`${prefix}-title`}>验证码异常提示</div>
        <div className={`${prefix}-subtitle`}>请检查您的设备</div>
        <div className={`${prefix}-desc`}>（1）解除应用软件的短信拦截，或者更换设备。</div>
        <div className={`${prefix}-desc`}>（2）欠费停机时，缴费以后一般是24小时恢复的，基本是24小时以内。建议更换手机号码或者第二天重新获取。</div>
        <div className={`${prefix}-desc`}>（3）联系运营商取消黑名单。</div>
        <div className={`${prefix}-desc`}>（4）如果是信号网络延迟，可耐心等待，或读秒完成后重试。</div>
        <div className={`${prefix}-desc`}>（5）换用其他登录方式</div>

        <Link className={`${prefix}-hightlight`} onClick={()=>{store.onLinkClick(KEFU_ONLINE)}}>联系客服</Link>
      </div>

    </div>
  </div>);
};

export default observer(CodeExceptionTip);
