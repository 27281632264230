import { action, observable } from "mobx";
import captchaHandlerDecorate from "../../utils/captcha-deco-util";
// import { CAPTCHA_PRE_AUTH } from "../../constants/urls";
import TinyInputStore from "../tiny-input/store";
import { useRef, useEffect } from "preact/hooks";
// import { phoneNumber } from "../../utils/common-regex";
import Services from "../../services";
import ToastStore from "../toast/store";
import lockAsync from "../../utils/lock-async";

class SmsCodeInputStore {
  //手机号码输入框
  tinyInputPhoneNum = new TinyInputStore();

  //验证码输入框
  tinyInputPhoneCode = new TinyInputStore();

  //toast
  toast = new ToastStore();

  //toast内容
  @observable
  toastContent = {
    msg: ""
  };

  //发送验证码类型"login","register"
  @observable
  type;

  /**
   * 手机相关配置
   * @type {*[]}
   */
  @observable
  phoneMsgConfig = [
    {
      key: "phoneNum", type: "tel", placeholder: this.type === 'login' ?"账号/手机/邮箱" : "手机", store: this.tinyInputPhoneNum, onBlur: () => {
        this.phoneNum();
      }
    }, {
      key: "phoneCode", type: "text", placeholder: "6位数字", store: this.tinyInputPhoneCode, onBlur: () => {
        this.phoneCode();
      }
    }
  ];

  //获取验证码按钮/秒数
  @observable sendBtnText = "获取验证码";

  //起始秒数
  @observable count;

  //按钮是否不可点击
  @observable btnDisable = true;

  //倒计时
  @observable timer;

  //是否在加载极验
  @observable loading = false;

  //多手机绑定时选择需要发送验证码的手机号码
  @observable phoneNumberList;

  //选择的手机号的contactway
  @observable contactway;

  //发送的手机号
  @observable maskedContactway;

  //更新弹窗内容类型
  @observable onChangeModalType;

  //清空该账号cookie
  @observable removeContactway;

  //获取记录的cookie
  @observable getContactway;

  //客户端标识
  @observable client = window.XPASS.loginConfig?.passportLoginType?.client ||'default';

  /**
   * 更新警告提示
   * @param type
   * @param msg
   */
  setWarnTip(type, msg) {
    this.phoneMsgConfig = this.phoneMsgConfig.map((item) => item.key === type ? {
      ...item,
      warnTip: msg
    } : item);
  }

  /**
   * 校验输入框的值
   */

  phoneNum() {
    if (!this.tinyInputPhoneNum.value) {
      return this.setWarnTip(this.phoneMsgConfig[0].key, "请输入通行证账号");
    }

    // else if (!phoneNumber.test(this.tinyInputPhoneNum.value)) {
    //   return this.setWarnTip(this.phoneMsgConfig[0].key, "请输入正确的手机号");
    // }

    this.setWarnTip(this.phoneMsgConfig[0].key, "");
    return this.tinyInputPhoneNum.value;
  }


  /**
   * 验证码
   */
  phoneCode() {
    if (!this.tinyInputPhoneCode.value) {
      return this.setWarnTip(this.phoneMsgConfig[1].key, "请输入验证码");
    }
    this.setWarnTip(this.phoneMsgConfig[1].key, "");
    return this.tinyInputPhoneCode.value;
  }

  /**
   * 关闭极验加载提示
   */
  closeCaptchaLoadingTip = () =>{
    this.toast.onClose();
    this.loading = false;
  }

  /**
   * 点击"获取验证码"
   */
  @action.bound
  @lockAsync()
  async onSendCodeClick() {
    if (this.phoneNum()) {
      this.loading = true;
      this.toastContent = { msg: "加载中" };
      if(this.type==="login"){
        await this.getContactway && this.getContactway();
        await this.sendCodeByLogin().catch(err=>{
          this.closeCaptchaLoadingTip();
          console.error('sendCodeByLogin catch msg',err.msg);
        });
      }else{
        await this.sendCode().catch(err=>{
          this.closeCaptchaLoadingTip();
          console.error(err.msg);
        });
      }
      this.closeCaptchaLoadingTip();

    } else {
      this.tinyInputPhoneNum.elementRef?.current?.focus();
    }
    // this.closeCaptchaLoadingTip();
  }

  /**
   * 手机登录发送验证码
   * @param authParams
   * @returns {Promise<void>}
   */
  @action.bound
  @lockAsync()
  @captchaHandlerDecorate()
  async sendCodeByLogin({ ...authParams }) {
    try{
      await Services.sendCodeByLogin({
        account: this.phoneNum(), ...authParams,
        client: this.client,//"default",
        contactway:this.contactway
      }).then((res) => {
        this.count = res.remaining - 1;//59;
        this.maskedContactway = res.contactway;
        //请求成功,倒计时开始
        this.timer = setInterval(() => {
          this.count = this.count - 1;
        }, 1000);

        this.tinyInputPhoneCode.elementRef?.current?.focus();

      }).catch(err => {
        if(err.code === -20142 /* 通行证账号未绑定手机 */){
          this.removeContactway && this.removeContactway();
          this.onChangeModalType && this.onChangeModalType(2);

        }else if(err.code === -20157 /* 该手机号登录存在多个帐号，请选择接收验证码的手机 */){
          this.removeContactway && this.removeContactway();
          this.phoneNumberList = err.data.list;
          this.onChangeModalType && this.onChangeModalType(3);
        }else{
          console.log('sendCodeByLogin error:',err,err.message);
          this.toastContent = { msg: err.message };
        }

      });
    }catch (err){
      console.log('sendCodeByLogin err:',err);
    }
  }

  /**
   * 注册获取验证码
   * @param authParams
   * @returns {Promise<void>}
   */
  @action.bound
  @lockAsync()
  @captchaHandlerDecorate()
  async sendCode({ ...authParams }) {
    try{
      await Services.sendCode({
        mobile: this.phoneNum(), ...authParams,
        client: this.client,//"default",
        channel: "register"
      }).then(() => {
        // console.info('code:', res);
        this.count = 59;
        //请求成功,倒计时开始
        this.timer = setInterval(() => {
          this.count = this.count - 1;
        }, 1000);

        this.tinyInputPhoneCode.elementRef?.current?.focus();
        window.XPASS.$$tracker.getCodeSuccess();

      }).catch(err => {
        this.toastContent = { msg: err.message };
        window.XPASS.$$tracker.getCodeFail();
      });
    }catch (err){
      console.log('sendCode err:',err);
    }
  }


  use() {

    this.tinyInputPhoneNum.elementRef = useRef();
    this.tinyInputPhoneCode.elementRef = useRef();


    useEffect(() => {
      return () => {
        clearInterval(this.timer);
        this.btnDisable = false;
        this.sendBtnText = "获取验证码";
        this.toastContent = { msg: "" };
      };
    }, []);

    useEffect(
      () => {
        if (this.count > 0) {
          this.btnDisable = true;
          this.sendBtnText = `${this.count}s`;
        } else {
          if(this.timer){
            clearInterval(this.timer);
          }
          this.btnDisable = false;
          this.sendBtnText = "获取验证码";
        }
      },

      [this.count]
    );

    useEffect(() => {
      if (this.toastContent.msg) {
        this.toast.visible = true;
        this.toast.msg = this.toastContent.msg;

      }
    }, [this.toastContent]);

    useEffect(()=>{
      if(this.type){
        this.phoneMsgConfig[0].placeholder = this.type === 'login' ?"账号/手机/邮箱" : "手机";
      }
    },[this.type])

  }
}

export default SmsCodeInputStore;
