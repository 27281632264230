import {action, observable} from "mobx";
import {isMobile} from "../../utils/device-utils";
import {KEFU_ONLINE, XOYO_REGISTER, XOYO_REGISTER_PC} from "../../constants/urls";

class SendCodeFaqStore {
  @observable onChangeModalType;

  /**
   * 点击链接
   */
  onLinkClick = (url)=>{
    window.open(url);
  }

  /**
   * 点击注册
   */
  onXOYORegisterClick = () =>{
    const url = isMobile() ? XOYO_REGISTER : XOYO_REGISTER_PC;
    window.open(url)
  }

  /**
   * 点击"登录其它账号"
   */
  @action.bound
  onReturnLoginClick(){
    this.onChangeModalType && this.onChangeModalType(1);
  }

  //点击后退
  onReturnClick = () => {
    this.onChangeModalType && this.onChangeModalType(3);
  }

  constructor({ ...rest } = {}) {
    Object.assign(this, rest);
  }

  use() {}
}
export default SendCodeFaqStore;
