import { h } from "preact";
import { observer } from "mobx-preact";
import SmsCodeInput from "../sms-code-input";
import TinyInput from "../tiny-input";
import "./index.less";
import Toast from "../toast";
import cx from "classnames";
import { KEFU_ONLINE, PRIVACY_POLICY, SERVICE_AGREEMENT, XOYO_REGISTER, XOYO_SIGNUP } from "../../constants/urls";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-common-register-mobile":"xfe-common-register";

const Register = ({ store }) => {
  store.use();

  return (<div className={`${prefix}-register`}>
    <div className={`${prefix}-registerWrap`}>

      <div className={`${prefix}-registerTitle`}>
        <span>注册金山通行证</span>
        <span className={`${prefix}-titleRight`}>已有账号？
          <Link onClick={() => {
            store?.onChangeContent(0);
          }}>去登录</Link></span>
      </div>

      <form onSubmit={store?.onRegisterClick}>
        {!isIE9() && <input type="submit" hidden/>}

        <div className={`${prefix}-registerMsgGroup`}>
          {store?.registerMsgConfig?.length > 0 && store?.registerMsgConfig.map((item, index) => {
            return <TinyInput name={item.key} store={item.store} key={index} type={item.type} placeholder={item.placeholder} warnTip={item?.warnTip} onBlur={item?.onBlur} maxLength={item?.maxlength}/>;
          })}
          <SmsCodeInput store={store?.smsCodeInput}/>
        </div>

        <div className={`${prefix}-identityMsgGroup`}>
          <div className={`${prefix}-tip`}>* 根据国家规定请填写真实身份信息，官方会严格保护您的隐私</div>
          {store?.identityMsgConfig?.length > 0 && store?.identityMsgConfig.map((item, index) => {
            return <TinyInput name={item.key} store={item.store} key={index} type={item.type} value={item.store.value} placeholder={item.placeholder} warnTip={item?.warnTip} onBlur={item?.onBlur}/>;
          })}
        </div>

        <div className={`${prefix}-agreementGroup`}>

          {store?.agreementTip && <div className={`${prefix}-warnTip`}>请阅读并同意协议</div>}

          <div className={`${prefix}-agreementRadioGroup`}>
            <a onClick={store.onAgreeClick} className={`${prefix}-agreementBtn`}>
              <div className={cx(`${prefix}-radio`, store?.agreeStatus && `${prefix}-radioChecked`)}/>
              {store?.agreeStatus && <span className={`${prefix}-tick`}/>}
            </a>

            <div className={`${prefix}-agreeLink`}>我已阅读并同意
              <Link target="_blank" href={SERVICE_AGREEMENT}>金山网络服务使用协议</Link>
              <span className={`${prefix}-line`}>|</span>
              <Link target="_blank" href={PRIVACY_POLICY}>金山通行证隐私权政策</Link>
            </div>
          </div>


        </div>

        <Link className={store?.themeImgs?.button ? `${prefix}-btnRegister` :`${prefix}-btnRegisterDefault`} style={store?.themeImgs?.button && {backgroundImage: `url(${store?.themeImgs?.button})` }} onClick={store?.onRegisterClick}>立即注册</Link>

      </form>

      <div className={`${prefix}-registerFooter`}>
        <div className={`${prefix}-btnOnline`}>注册问题请
          <Link target="_blank" href={KEFU_ONLINE}>联系在线客服</Link>
        </div>
        <Link className={`${prefix}-btnOtherReg`} target="_blank" href={store.registerLink}>其他方式注册</Link>
      </div>

    </div>
    <Toast store={store?.toast}/>
  </div>);
};

export default observer(Register);
