import { isMobile } from "../utils/device-utils";
import isMaster from "./is-master";
import domainMapper from "./domain-mapper";

export const XOYO_URL = isMaster ? domainMapper.getWsUrl() : "//test-ws.xoyo.com";

export const CAPTCHA_ZT_PRE_AUTH = isMaster ? "//ws.xoyo.com/core/captcha/pre_auth" : "//test-ws.xoyo.com/core/captcha/pre_auth";

export const XOYO_PLATFORM_ADDRESS = isMaster ? domainMapper.getPfUrl() : "//my-api-dev.xoyo.com";

export const PASSPORT_ROOT_ADDRESS = `${XOYO_PLATFORM_ADDRESS}/passport/common_api`;

export const CAPTCHA_PRE_AUTH = `${PASSPORT_ROOT_ADDRESS}/pre_auth`;

// noinspection JSUnresolvedVariable
export const CAPTCHA_XOYO_PIC_URL = `${XOYO_PLATFORM_ADDRESS}/passport/common_api/captcha`;

//xoyo极验
export const CAPTCHA_XOYO_PRE_AUTH = `${PASSPORT_ROOT_ADDRESS}/pre_auth`;

//专题极验
export const CAPTCHA_ZT_PIC_URL = `${XOYO_URL}/core/captcha/create`;

//忘记账号
export const JX3_FORGET_ACCOUNT = "//kefu.xoyo.com/?r=jx3_recover_account&service_id=950";

//忘记密码
export const FORGET_PASSWORD = isMobile() ? "//m.xoyo.com/?source=index_top#/forget-password?redirect=%2Fsecurity" : "//security.xoyo.com/forget-password";

//金山网络服务使用协议
export const SERVICE_AGREEMENT = "//help.xoyo.com/agreement?name=kingsoft-network-service-agreement";

//金山通行证隐私权政策
export const PRIVACY_POLICY = "//help.xoyo.com/agreement?name=kingsoft-online-privacy-policy";

//在线客服
export const KEFU_ONLINE = "//kefu.xoyo.com/index.php?r=gamelist";

//其它登录方式
export const XOYO_SIGNUP = "//passport.xoyo.com/signup";

//xoyo移动端注册
export const XOYO_REGISTER = isMaster ? "//m.xoyo.com/#/signup?type=mobile" : "//m-dev.xoyo.com/passport/signup.html";

//xoyo个人中心
export const XOYO_PERSONAL_CENTER = isMobile() ? "//m.xoyo.com/?source=index_top#/profile" : "//i.xoyo.com";

//xoyo绑定手机
export const XOYO_BIND_PHONE_ADDRESS = isMaster ? "//passport.xoyo.com/static/face-detect/bind-mobile/#/" : "//passport-dev.xoyo.com/static/face-detect/bind-mobile/#/";

//xoyo注册
export const XOYO_REGISTER_PC = isMaster ? "//passport.xoyo.com/signup" : "//passport-dev.xoyo.com/signup";

//一键登录
export const ONE_KEY_ADDRESS = isMaster ? "//passport.xoyo.com/static/one-key-login/" : "//passport-dev.xoyo.com/static/one-key-login/";

//是否开启一键登录的cms配置
export const IS_ONE_KEY_CMS_API = isMaster ? "//activity.xoyo.com/api/config/tag/" : "//test-zt.xoyo.com/api/config/tag/";
export const IS_ONE_KEY_CMS_TAG = isMaster ? "zt/common_login_one_key_switch" : "zt/common_login_one_key_switch";

//埋点上报特殊项目标识
export const TRACKER_PROJECT_ID_CMS_API = isMaster ? "//activity.xoyo.com/api/config/tag/" : "//test-zt.xoyo.com/api/config/tag/";
export const TRACKER_PROJECT_ID_CMS_TAG = isMaster ? "zt/common_login_special_project_id" : "zt/common_login_special_project_id";

