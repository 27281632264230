import { h } from "preact";
import { observer } from "mobx-preact";
import TinyInput from "../tiny-input";
import cx from "classnames";
import "./index.less";
import Toast from "../toast";
import Link from "../link";
import { isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-login-code-input-mobile" : "xfe-login-code-input";

const SmsCodeInput = ({ store, className, ...rest }) => {
  store.use();
  return (<div>
    <TinyInput name={store?.phoneMsgConfig[0]?.key} store={store?.phoneMsgConfig[0]?.store} type={store?.phoneMsgConfig[0]?.type} value={store?.phoneMsgConfig[0]?.store?.value} placeholder={store?.phoneMsgConfig[0]?.placeholder} warnTip={store?.phoneMsgConfig[0]?.warnTip} onBlur={store?.phoneMsgConfig[0]?.onBlur} className={className} />
    <div className={`${prefix}-codeGroup`}>
      <TinyInput name={store?.phoneMsgConfig[1]?.key} store={store?.phoneMsgConfig[1]?.store} type={store?.phoneMsgConfig[1]?.type} value={store?.phoneMsgConfig[1]?.store?.value} placeholder={store?.phoneMsgConfig[1]?.placeholder} warnTip={store?.phoneMsgConfig[1]?.warnTip} className={cx(`${prefix}-codeInput`)} groupClassName={`${prefix}-inputGroup`} onBlur={store?.phoneMsgConfig[1]?.onBlur}/>
      {store.loading ?<div className={`${prefix}-loading`}><span className={`${prefix}-condeBtnLoading`}/></div> :<Link className={cx(`${prefix}-codeBtn`, store?.btnDisable && `${prefix}-btnDisabled`)} onClick={()=>{store.onSendCodeClick()}}>{store.sendBtnText}</Link>}
    </div>
    <Toast store={store?.toast}/>
  </div>);

};
export default observer(SmsCodeInput);
