import { h, Fragment } from "preact";
import ModalStore from "./components/modal/store";
import Modal from "./components/modal";
import RenderComponent from "./components/render-component";

export default function App(props) {
  const {isModal} = props;
  const modal = new ModalStore(props);
  return (
    <>
      {isModal ? <Modal store={modal}/> : <RenderComponent store={modal} />}
    </>

  );
}
