/**
 * 身份证号码
 * @type {RegExp}
 */
export const chineseId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

/**
 * 中文名
 * @type {RegExp}
 */
export const chineseName = /^[\u4e00-\u9fa5]{2,8}$/;

/**
 * 收件人名
 * @type {RegExp}
 */
export const addresseeName = /^[\u4e00-\u9fa5]{2,25}$/;

/**
 * 手机号码
 * @type {RegExp}
 */
export const phoneNumber = /^1[3456789]\d{9}$/;

/**
 * 通行证账号格式
 * @type {RegExp}
 */
export const usernameRegex = /^[a-zA-Z]\w{4,18}$/;
