/**
 * 页面刷新, 针对微信中安卓 window.location['reload']() 无效的问题处理
 */


import { isIOS } from "./device-utils";

export default function reload() {
  if (isIOS()) {
    /**
     * 绕过部署时检测, 允许这个行为
     */
    window.location.reload.apply(window.location);
    return;
  }
  let newTs = "&ts=" + Date.now();
  let search = window.location.search;
  let hasTs = /&ts=\d+/.test(search);
  if (hasTs) {
    search = window.location.search.replace(/&ts=\d+/, newTs);
  } else {
    if (!search) {
      search = "?";
    }
    search += newTs;
  }
  if (window.location.origin) {
    window.location.href = window.location.origin + window.location.pathname + search + window.location.hash;
  } else {
    window.location.href =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "") +
      window.location.pathname +
      search +
      window.location.hash;
  }
}
