import CaptchaHandler from "@xfe/captcha-handler";
import jsonp from "./jsonp-util";
import { CAPTCHA_XOYO_PIC_URL, CAPTCHA_XOYO_PRE_AUTH, CAPTCHA_ZT_PIC_URL, CAPTCHA_ZT_PRE_AUTH } from "../constants/urls";

// noinspection SpellCheckingInspection
/**
 * 极验的账号, 用于区分专题或通用登录极验账号
 */
let geetestGt;

/**
 * 获取场景
 */
const getScene = sceneName => {
  // noinspection SpellCheckingInspection
  switch (sceneName) {
    case 'xoyo':
      return {
        getGeeTestSettings: (captchaUrl = CAPTCHA_XOYO_PRE_AUTH) => {
          return {
            url: captchaUrl,
            requestPreAuthImpl(url, params) {
              /**
               * 要求请求后返回 {message, data} 的数据结构
               */
              return jsonp(url, params).then(response => {
                if (response.code === 1 && response.data.mode === 2 /* 极验验证码模式 */) {
                  geetestGt = response.data.config.gt;
                }
                return response;
              });
            }
          };
        },
        pictureSettings: {
          /**
           * 降级 Image 地址,
           * 针对 XOYO 网使用 CAPTCHA_XOYO_PIC_URL
           * 针对专题相关使用 CAPTCHA_ZT_PIC_URL
           */
          captchaPicUrl: CAPTCHA_XOYO_PIC_URL
        }
      };
    case 'zt':
      // noinspection SpellCheckingInspection
      return {
        getGeeTestSettings: (captchaUrl = CAPTCHA_ZT_PRE_AUTH) => {
          return {
            url: captchaUrl,
            requestPreAuthImpl(url, params) {
              /**
               * 要求请求后返回 {message, data} 的数据结构
               */
              return jsonp(url, params).then(response => {
                if (response.code === 1 && response.data.mode === 2 /* 极验验证码模式 */) {
                  geetestGt = response.data.config.gt;
                }
                return response;
              });
            }
          };
        },
        pictureSettings: {
          /**
           * 降级 Image 地址,
           * 针对 XOYO 网使用 CAPTCHA_XOYO_PIC_URL
           * 针对专题相关使用 CAPTCHA_ZT_PIC_URL
           */
          captchaPicUrl: CAPTCHA_ZT_PIC_URL
        }
      };
    default:
      throw new Error(`验证码场景不合法 = ${sceneName}, 当前场景只支持 xoyo 或者 zt`);
  }
};

// noinspection SpellCheckingInspection
/**
 * 二次封装装饰函数
 * @param {'xoyo'|'zt'} sceneName - 场景名
 * @returns {*}
 */
const captchaHandlerDecorate = (sceneName = 'xoyo') => {
  let params;
  if (typeof sceneName === 'string') {
    const { getGeeTestSettings, ...rest } = getScene(sceneName);
    params = {
      geeTestSettings: getGeeTestSettings(),
      ...rest
    };
  } else {
    params = sceneName;
  }
  return CaptchaHandler.decorate(params, ([params], captchaData) => {
    // noinspection SpellCheckingInspection
    const newParams = Object.assign({}, params, captchaData, {
      geetest_gt: geetestGt
    });
    return [newParams];
  });
};

/**
 * API对外暴露,
 * 用例:
 *
 * 原函数:
 *  // 发起登录请求
 *  async loginAsync({account, password}) {
 *   const {login} = this.props;
 *   return await login({account, password});
 * }
 *
 * 加入极验验证码:
 * @captchaHandlerDecorate()
 *  async loginAsync({account, password, ...authParams}) {
 *   const {login} = this.props;
 *   return await login({account, password, ...authParams});
 * }
 *
 * 效果:
 * 点击或者触发上述方法后自动弹出极验验证码
 */
export default captchaHandlerDecorate;
