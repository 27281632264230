import { h, Fragment } from "preact";
import RegisterSuccess from "../register-success";
import Login from "../login";
import Register from "../register";
import { observer } from "mobx-preact";

const RenderComponent = ({ store }) => {
  store.use();
  return (
          <>
            {store?.contentType === 2 ?
            <RegisterSuccess store={store?.registerSuccess}/>:
            <>
              {store?.contentType === 0 && <Login store={store?.login}/>}
              {store?.contentType === 1 && <Register store={store?.register}/>}
            </>
          }
          </>
      );
};

export default observer(RenderComponent);
