import { h } from "preact";
import cx from "classnames";
import "./index.less";
import wrapStore from "../../utils/wrap-store";
import TinyInputStore from "../tiny-input/store";
import { isIE9, isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-common-login-tiny-input-mobile" : "xfe-common-login-tiny-input";
/**
 * 小型输入框
 * @param {TinyInputStore} store
 * @param {string} className
 * @param {object} rest
 * @returns {JSX.Element}
 * @constructor
 */
const TinyInput = wrapStore(({ store, className, groupClassName, warnTip, onBlur, name, ...rest }) => {
  store.use();
  const { placeholder } = { ...rest };
  return (<div className={cx(`${prefix}-inputGroup`, groupClassName)}>
    <input ref={store.elementRef} className={cx(className, `${prefix}-input`, warnTip && `${prefix}-warn`)} value={store.value} onChange={store.onChange} {...rest} onBlur={() => {
      store.onBlur();
      onBlur();
    }} onFocus={() => {
      store?.onPlaceholderClick(store.elementRef, name);
    }}/>
    {isIE9() && !store?.value &&
    <a className={cx(`${prefix}-placeholderTip`, `${prefix}-placeholderTip-${name}`)} onClick={() => {
      store?.onPlaceholderClick(store.elementRef, name);
    }}>{placeholder}</a>}
    <span className={`${prefix}-warnTip`}>{warnTip}</span>
  </div>);
}, TinyInputStore);

export default TinyInput;
