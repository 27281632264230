/**
 * 加载JavaScript脚本
 * @param src
 */

export default function scriptCreate(src){
  if(!isInclude(src)){
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
  }

}

/**
 * 是否已经加载该脚本
 * @param src
 * @returns {boolean}
 */
function isInclude(src){
  const js = /js$/i.test(src);
  const es = document.getElementsByTagName(js ? 'script' : 'link');
  for (let i = 0; i < es.length; i++)
    if (es[i][js ? 'src' : 'href'].indexOf(src) !== -1) return true;
  return false;
}
