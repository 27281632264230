import { h } from "preact";
import { observer } from "mobx-preact";
import "./index.less";
import { isMobile } from "../../utils/device-utils";


const prefix = isMobile() ? "register-success-mobile" : "register-success";

const RegisterSuccess = ({ store }) => {
  store.use();
  return (<div className={`${prefix}-registerSuccess`}>
    <div className={`${prefix}-success`}/>
  </div>);

};

export default observer(RegisterSuccess);
