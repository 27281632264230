import { h } from "preact";
import cx from "classnames";
import "./index.less";
import { isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-login-mobile-link" : "xfe-login-link";

const Link = (props) => {
  const { onClick, children, className, ...restProps } = props;
  return (
    <a className={cx(`${prefix}-link`, className)} onClick={onClick} {...restProps} >{children}</a>
  );

};

export default Link;

