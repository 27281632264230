import StReportSdk from "@xfe/st-report-sdk";
import { config } from "./create-tracker";

export const stReportSdk = StReportSdk.getInstance({
  projectIdentifier: config.projectIdentifier,
  eventTags: config.eventTags,
  eventGroup: config.eventGroup
}, false);

export const report = ({eventName /* String 事件名 一般是英文 */,
                         eventDescription /* String 事件描述 一般是中文 */,
                         eventGroup /* String 事件组，一般只有一个，例如“webstat”，若有多个，则用空格隔开，注意是字符串不是数组哦 */,
                         eventDataValue = {} /* String 事件数据*/,
                         types,
                         ...restProps /* 其他参数详见 sdk https://gitlab.xsjcs.cn/xfe/st-report-sdk 和 Wiki http://wiki.xsjcs.cn/pages/viewpage.action?pageId=18068827 */})=>{
  try {
    stReportSdk.report({
      eventName,
      eventDescription,
      eventDataValue,
      eventGroup,
      eventTags: config.eventTags,
      ...restProps
    });
  } catch (e) {
    // 为了能够继续往后走, 但是能够上报
    setTimeout(() => {
      throw e;
    });
  }
};

/**
 * 计算上一次路由时间,
 * 对应买点中的: ev_d.td_pl(秒)
 */
export const getDiffRouteTime = StReportSdk.getDiffRouteTime;
