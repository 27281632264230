/**
 * 非xoyo域名的处理 后续均需要按照以下格式进行转发
 * 如新增 XXXX.com 域名 专题需要自动请求
 * https://py-api.XXXX.com/ws  转发到专题（ws.xoyo.com）
 * https://py-api.XXXX.com/pf   转发到xoyo（pf-api.xoyo.com）
 * https://py-api.XXXX.com/stat 转发到数据统计 (st.xoyo.com)
 *
 */

const locationHostSplits = window.location.hostname.split('.');
// 固定获取 'www.xxxx.com' 站点的 '.com'前的站点域名
const mainDomainName = locationHostSplits[locationHostSplits.length - 2];
// 获取站点的 '顶级域名'
const tldsName = locationHostSplits[locationHostSplits.length - 1];
// 是否只针对顶级域名为 '.com'的站点, 默认为true;
const isOnlyInCom = false;
/**
 * 转发到专题
 * @returns {string}
 */
const getWsUrl = () => {
  if (mainDomainName === 'xoyo') {
    return '//ws.xoyo.com'
  }
  return `//py-api.${mainDomainName}.${isOnlyInCom ? 'com' : tldsName}/ws`
}

/**
 * 转发到xoyo
 * @returns {string}
 */
const getPfUrl = () => {
  if (mainDomainName === 'xoyo') {
    return '//pf-api.xoyo.com'
  }
  return `//py-api.${mainDomainName}.${isOnlyInCom ? 'com' : tldsName}/pf`
}

/**
 * 转发到数据统计
 * @returns {string}
 */
const getStUrl = () => {
  return '//st.xoyo.com/report/web';
};

const domainMapper = {
  // 转发到专题
  getWsUrl: getWsUrl,
  // 转发到xoyo
  getPfUrl: getPfUrl,
  // 转发到数据统计
  getStUrl: getStUrl,

}

export default domainMapper;
